<template>
    <b-tr>
        <b-td class="sb__drag">
            <i class="fa fa-ellipsis-h sb__drag-icon" />
            <i class="fa fa-ellipsis-h sb__drag-icon" />
        </b-td>
        <b-td>
            <wit-input v-model="$v.item.name.$model" placeholder="Name" @input="emitInput" />
        </b-td>
        <b-td>
            <wit-select v-model="$v.item.mode.$model" :options="modeOptions" placeholder="Mode" @input="emitInput" />
        </b-td>
        <b-td>
            <wit-select v-model="$v.item.type.$model" :options="typeOptions" placeholder="Type" @input="emitInput" />
        </b-td>
        <b-td>
            <wit-input v-model="$v.item.dataStudio.description.$model" placeholder="Description" @input="emitInput" />
        </b-td>

        <b-td>
            <i class="fa fa-remove pointer-action" v-b-tooltip.hover title="Remove field" @click="removeField" />
        </b-td>
        <Portal v-if="item.type === 'RECORD'" :to="`schema-portal-${item.uuid}`">
            <b-tr>
                <b-td colspan="8">
                    <schema-list
                        v-model="$v.item.fields.$model"
                        :valid.sync="fieldsValid"
                        @input="emitInput"
                        @update:valid="emitUpdate"
                    />
                </b-td>
            </b-tr>
        </Portal>
    </b-tr>
</template>

<script>
import {Portal} from 'portal-vue'
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'

import {dataStudioTypes} from '@/shared/dataStudioTypes'

export default {
    components: {
        Portal,
        SchemaList: () => import('./SchemaList'),
        WitInput: () => import('@/components/Inputs/WitInput.vue'),
        WitSelect: () => import('@/components/Inputs/WitSelect.vue'),
    },
    props: {
        value: {
            type: Object,
        },
        valid: {
            type: Boolean,
        },
    },
    mixins: [validationMixin],
    validations: {
        item: {
            uuid: {},
            name: {required},
            mode: {required},
            type: {required},
            fields: {
                valid: function() {
                    return this.item.type === 'RECORD' ? this.fieldsValid : true
                },
            },
            dataStudio: {
                name: {},
                type: {},
                description: {},
            },
        },
    },
    data() {
        return {
            item: {
                uuid: this.$randomId(),
                name: '',
                mode: null,
                type: null,
                fields: [],
                dataStudio: {
                    name: '',
                    type: null,
                    description: '',
                },
            },
            modeOptions: ['NULLABLE', 'REPEATED'],
            typeOptions: ['FLOAT', 'INTEGER', 'RECORD', 'STRING', 'DATE', 'TIMESTAMP', 'BOOLEAN'],
            dsTypeOptions: dataStudioTypes,
            fieldsValid: false,
        }
    },
    created() {
        if (this.value) {
            this.item = {...this.item, ...this.value}
            this.emitUpdate()
        }
    },
    methods: {
        emitInput() {
            this.$emit('input', this.item)
            this.emitUpdate()
        },

        emitUpdate() {
            this.$emit('update:valid', !this.$v.$invalid)
        },

        removeField() {
            this.$emit('remove', this.item.uuid)
        },
    },
}
</script>

<style></style>
